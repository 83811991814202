@import "../../../_app.scss";
@import '../../../themes/access/base.scss';

body {background-color: #c51b1f;}

#header {
  background-color: #c51b1f;
}

.logo {
  background:  image-url("_file-protect/logo.png") no-repeat center;
}

.btn-primary {
  border-color:#a80002;
  text-shadow:0 1px 0 rgba(0, 0, 0, 0.4);
  color:#fff;
  background-color:#e14d4e;
  background-image:-moz-linear-gradient(top, #e14d4e, #c51b1f);
  background-image:-webkit-gradient(linear, 0 0, 0 100%, from(#e14d4e), to(#c51b1f));
  background-image:-webkit-linear-gradient(top, #e14d4e, #c51b1f);
  background-image:-o-linear-gradient(top, #e14d4e, #c51b1f);
  background-image:linear-gradient(to bottom, #e14d4e, #c51b1f);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary[disabled] {
  color:#fff;
  background-color:#cf1c20;
  background-image:-moz-linear-gradient(top, #f85757, #cf1c20);
  background-image:-webkit-gradient(linear, 0 0, 0 100%, from(#f85757), to(#cf1c20));
  background-image:-webkit-linear-gradient(top, #f85757, #cf1c20);
  background-image:-o-linear-gradient(top, #f85757, #cf1c20);
  background-image:linear-gradient(to bottom, #f85757, #cf1c20);
}
.btn-primary:active,
.btn-primary.active {
  color:#fff;
  background-color:#cf1c20;
}

/**/
.btn-primary > [class*="font-icon-"] {
  text-shadow:0 -1px rgba(0, 0, 0, 0.3);
  color:#fff;
}
