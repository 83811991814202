@import "../../_app.scss";

/*=================== Normal CSS ========================*/
html,
body {
  height: 100%;
  min-width: 280px;
  padding: 0;
}

/*=================== RESET ============================*/

/*
OVERRIDES
---------
Fix a few issues from boostrap.
*/
h1, h2, h3, h4, h5, h6 {
  text-rendering: auto;
}

.lt-ie8 ul.inline > li,
.lt-ie8 ol.inline > li {
  display: inline;
  zoom: 1;
}

.lt-ie8 .nav > li > a:hover {
  cursor: pointer;
}

ul.thumbnails li.span3:nth-child(4n + 1) {
  clear: left;
}

ul.thumbnails li.span4:nth-child(3n + 1) {
  clear: left;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  -webkit-transition: border linear 0s;
  -moz-transition: border linear 0s;
  -o-transition: border linear 0s;
  transition: border linear 0s;
  -webkit-transition: border-color linear 0.2s;
  -moz-transition: border-color linear 0.2s;
  -o-transition: border-color linear 0.2s;
  transition: border-color linear 0.2s;
}

/*
HELPERS
-------
Useful classes for dealing with things that happen fairly often.
*/
.strtoupper {
  text-transform: uppercase;
}

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
}

/*======================================================*/

#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: auto;

  /*sorts out bottom margin on last child*/

  background-image: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* FF3.6+ */
  background-image: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0, rgba(255, 255, 255, 0.4)), color-stop(70%, rgba(255, 255, 255, 0)));

  /* Chrome,Safari4+ */
  background-image: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* Chrome10+,Safari5.1+ */
  background-image: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* Opera 12+ */
  background-image: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* IE10+ */
  background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* W3C */

  background-size: 600px 500px;
  background-repeat: no-repeat;
  background-position: center 0;
  padding: 0 20px;
}

.logo {
  height: 66px;
  margin-bottom: 20px;
  display: block;
  background-size: contain;
}

.header,
.content,
.footer {
  margin-bottom: 10px;
}

.header a,
.header a:hover,
.header a:focus,
.footer a,
.footer a:hover,
.footer a:focus {
  color: #fff;
}

a:hover,
a:focus {
  text-decoration: none;
}

.header {
  padding-top: 20px;
}

.icon-home {
  background: image-url("16px_home.png") 0 0;
  width: 16px;
  height: 16px;
  margin-top: 0;
  line-height: 16px;
}

.footer ul.inline > li {
  padding-left: 20px;
  padding-right: 20px;
}

.footer a {
  font-size: 12px;
}

.wrappedContent {
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 18px #424242;
  box-shadow: 0 0 18px #424242;
  padding: 30px;
}

.fixedMediumWidth {
  width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.content h2 {
  font-family: "Myriad Pro", Calibri, Arial, Helvetica, "sans-serif", serif;
  font-weight: 100;
}

.content h6 {
  color: #7E7E80;
  font-weight: 100;
  font-size: 14px;
}

@media (max-width: 610px) {
  .fixedMediumWidth {
    width: auto;
  }

  .footer ul.inline > li {
    display: block;
  }

  .form-horizontal .control-label,
  .form-horizontal .controls,
  .header ul.inline {
    text-align: center;
  }
}

.form-horizontal .form-inline .controls {
  width: 220px;
}

.hidden-el {
  display: block !important;
  position: absolute !important;
  left: -9999em !important;
}

#wrap {
  min-height: 100%;
  height: 100%;
  overflow: auto;

  /*sorts out bottom margin on last child*/

  background-image: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* FF3.6+ */
  background-image: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0, rgba(255, 255, 255, 0.4)), color-stop(70%, rgba(255, 255, 255, 0)));

  /* Chrome,Safari4+ */
  background-image: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* Chrome10+,Safari5.1+ */
  background-image: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* Opera 12+ */
  background-image: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* IE10+ */
  background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* W3C */

  background-size: 600px 500px;
  background-repeat: no-repeat;
  background-position: center 0;
  padding: 0 20px;
}

.header,
.content,
.footer {
  margin-bottom: 10px;
}

.header a,
.header a:hover,
.header a:focus,
.footer a,
.footer a:hover,
.footer a:focus {
  color: #fff;
}

a:hover,
a:focus {
  text-decoration: none;
}

.header {
  padding-top: 20px;
}


.footer ul.inline > li {
  padding-left: 20px;
  padding-right: 20px;
}

.footer a {
  font-size: 12px;
}

.wrappedContent {
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 18px #424242;
  box-shadow: 0 0 18px #424242;
  padding: 30px;
}

.fixedMediumWidth {
  width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.content h2 {
  font-family: "Myriad Pro", Calibri, Arial, Helvetica, "sans-serif", serif;
  font-weight: 100;
}

.content h6 {
  color: #7E7E80;
  font-weight: 100;
  font-size: 14px;
}

@media (max-width: 610px) {
  .fixedMediumWidth {
    width: auto;
  }

  .footer ul.inline > li {
    display: block;
  }

  .form-horizontal .control-label,
  .form-horizontal .controls,
  .header ul.inline {
    text-align: center;
  }
}

.form-horizontal .form-inline .controls {
  width: 220px;
}

.hidden-el {
  display: block !important;
  position: absolute !important;
  left: -9999em !important;
}

/* Login Panel - Create account */

.panel {
  width:100%;
}

form + .panel {
  padding-top:20px;
}

/**/
.panel .title {
  padding:0;
  border-top:1px solid #e3e3e3;
  margin:0;
  text-align:center;
  font-size:15px;
  color:#706c6c;
}
.panel .title > * {
  padding:5px 10px;
  position:relative;
  top:-20px;
  background:#fff;
}

/**/
.panel .heading {
  float:left;
}
.panel .heading + .btn {
  float:right;
  margin-top:-15px;
}

.heading {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}
/* END - Login Panel - Create account */
//colors
$black_40: rgba(0,0,0,0.4);
$white_10: rgba(255,255,255,0.1);
$color_pumice_approx: #c8c8c8;
$color_black_haze_approx: #f7f7f7;
$color_mercury_approx: #e3e3e3;
$color_dove_gray_approx: #706c6c;
$white: #fff;
$color_fuscous_gray_approx: #555;
$black: #000;
$color_emperor_approx: #4e4948;
$color_cloud_approx: #c7c3be;

//fonts
$font_0: calibri;
$font_1: arial;
$font_2: sans-serif;

//urls
$url_0: url(/res/7a8a27/esabot/pamon/img/iconsmall.png);

//@extend-elements
//original selectors
//input:focus:invalid:focus, textarea:focus:invalid:focus, select:focus:invalid:focus
%extend_1 {
  border-color: $color_pumice_approx;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  color: $color_fuscous_gray_approx;
}

//original selectors
//.btn-primary, .btn-primary.disabled, .btn-primary[disabled]
%extend_3 {
  border: none;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: none;
  color: $white;
  background-image: none;
}

//original selectors
//.btn-primary:hover, .btn-primary:focus
%extend_4 {
  color: $white;
  background-image: none;
}

//original selectors
//.btn-primary:active, .btn-primary.active
%extend_5 {
  color: $white;
  background-image: none;
}

//original selectors
//.btn-default, .btn-default:hover, .btn-default:active, .btn-default:focus, .btn-default.disabled, .btn-default[disabled]
%extend_6 {
  font-size: 14px;
  color: $white;
  background-color: $color_cloud_approx;
  background-image: none;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: none;
  border: none;
}


.btn {
  padding: 10px 30px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
  font-family: $font_0, $font_1, $font_2, $font_2;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    background-position: 0;
  }
  &:focus {
    background-position: 0;
  }
}
.uppercase {
  text-transform: uppercase;
}
.ucwords {
  text-transform: uppercase;
}
.capitalise {
  text-transform: capitalize;
}
.capitalize {
  text-transform: capitalize;
}
.clearfix {
  display: inline-block;
  &:after {
    content: ".";
    height: 0;
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
  }
}
.centerIt {
  width: 100%;
  float: left;
  > .push {
    float: left;
    position: relative;
    left: 50%;
    > * {
      position: relative;
      left: -50%;
    }
  }
}
.heading {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}
.header {
  padding-top: 50px;
  position: relative;
  ul {
    padding: 10px 0 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  li {
    position: relative;
    a {
      padding: 0 5px;
      text-transform: uppercase;
    }
    + li {
      border-left: 1px solid $black_40;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px solid $white_10;
      }
    }
  }
}
.control-group {
  input[type="text"] {
    border-color: $color_pumice_approx;
    background: $color_black_haze_approx;
  }
  input[type="email"] {
    border-color: $color_pumice_approx;
    background: $color_black_haze_approx;
  }
  input[type="tel"] {
    border-color: $color_pumice_approx;
    background: $color_black_haze_approx;
  }
  input[type="password"] {
    border-color: $color_pumice_approx;
    background: $color_black_haze_approx;
  }
}
.panel {
  width: 100%;
  .title {
    padding: 0;
    border-top: 1px solid $color_mercury_approx;
    margin: 0;
    text-align: center;
    font-size: 15px;
    color: $color_dove_gray_approx;
    > * {
      padding: 5px 10px;
      position: relative;
      top: -20px;
      background: $white;
    }
  }
  .heading {
    float: left;
    + .btn {
      float: right;
      margin-top: -15px;
    }
  }
}
.wrong-brand-banner {
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  z-index: 2;
  background: $black;
  color: $white;
  text-align: center;
}
@media(max-width:767px) {
  .header {
    padding-top: 36px;
  }
  .form-horizontal + .panel {
    text-align: center;
  }
  .panel .heading {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    text-align: center;
    + .btn {
      float: none;
      margin: 0;
    }
  }
}
@media(max-width:610px) {
  #form-login {
    margin-bottom: 10px;
    margin-bottom: 0;
    .control-group.form-inline .controls {
      max-width: 100%;
      label {
        display: none;
      }
    }
  }
  .form-horizontal {
    .control-group {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .controls {
      margin-left: 0;
    }
  }
  .control-group {
    input[type="text"] {

      width: 100%;
      height: 50px;
      font-size: 25px;
      line-height: 24px;
    }
    input[type="email"] {

      width: 100%;
      height: 50px;
      font-size: 25px;
      line-height: 24px;
    }
    input[type="password"] {
      width: 100%;
      height: 50px;
      font-size: 25px;
      line-height: 24px;
    }
  }
  .btn-primary {
    float: none;
    width: 95%;
    margin-left: 5px;
    height: 60px;
    font-size: 20px;
  }
  .control-label {
    display: none;
  }
  .content {
    h2 {
      display: none;
    }
    h6 {
      display: none;
    }
  }
  .footer {
    a {
      font-size: 14px;
      line-height: 20px;
    }
    ul li:first-child a {
      font-size: 18px;
      line-height: 50px;
    }
  }
  hr {
    display: none;
  }
  form + .panel {
    display: none;
  }
}
.hidden-el {
  height: 1px !important;
  padding: 0 !important;
  width: 1px;
  left: -3000px;
  position: absolute;
}
body {
  color: $color_emperor_approx;
}
.btn-primary {
  @extend %extend_3;
  &.disabled {
    @extend %extend_3;
  }
  &:hover {
    @extend %extend_4;
  }
  &:focus {
    @extend %extend_4;
  }
  &:active {
    @extend %extend_5;
  }
  &.active {
    @extend %extend_5;
  }
  > [class*="font-icon-"] {
    color: $white;
  }
}
.btn-primary[disabled] {
  @extend %extend_3;
}
.btn-default {
  @extend %extend_6;
  &:hover {
    @extend %extend_6;
  }
  &:active {
    @extend %extend_6;
  }
  &:focus {
    @extend %extend_6;
  }
  &.disabled {
    @extend %extend_6;
  }
}
.btn-default[disabled] {
  @extend %extend_6;
}
i[class^="icon-small"] {
  background: $url_0 no-repeat right 0;
  display: inline-block;
}
.lt-ie8 .btn {
  line-height: normal;
}
html[xmlns] .clearfix {
  display: block;
}
* html .clearfix {
  height: 1%;
}
#form-login .control-group.form-inline .controls {
  width: auto;
  display: block;
  margin: 0 auto;
  max-width: 285px;
  label {
    float: left;
    position: relative;
    top: 10px;
    + .btn {
      margin-left: 20px;
    }
  }
}
form + .panel {
  padding-top: 20px;
}
input:focus:invalid:focus {
  @extend %extend_1;
}
textarea:focus:invalid:focus {
  @extend %extend_1;
}
select:focus:invalid:focus {
  @extend %extend_1;
}
#form-forgottenPassword .control-group .controls {
  width: auto !important;
}
i.icon-small-arrowr {
  padding: 0;
  height: 19px;
  width: 20px;
  background-position: -7px -68px;
}
